import baseTheme from '@bottlebooks/gatsby-theme-event/src/theme';
import merge from 'lodash/merge';
import { darken } from 'polished';

const theme = {
  ...baseTheme,
  fontUrls: [
    'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap',
  ],
  fonts: merge({}, baseTheme.fonts, {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  }),
  fontWeights: {
    ...baseTheme.fontWeights,
    heading: '700',
    body: '400',
  },
  title: merge({}, baseTheme.title, {
    _base: {
      WebkitFontSmoothing: 'antialiased',
    }, // Add antialiasing to titles.
  }),
  colors: merge({}, baseTheme.colors, {
    ...baseTheme.colors,
    primary: '#888',
    secondary: darken(0.1)('#888'),
    accent: 'black',
  }),
  label: {
    ...baseTheme.label,
    _base: { ...baseTheme.label._base, color: '#666' },
  },
  text: {
    ...baseTheme.text,
  },
  styles: {
    ...baseTheme.styles,
    root: {
      ...baseTheme.styles.root,
      WebkitFontSmoothing: 'subpixel-antialiased',
    },
  },
};

// console.log(theme);
export default theme;
